@font-face {
  font-family: "Gilroy-Black";
  src: url("static/fonts/Gilroy-Black.otf") format("opentype");
}

@font-face {
  font-family: "Gilroy-Bold";
  font-weight: bold;
  src: url("static/fonts/Gilroy-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Gilroy-ExtraBold";
  src: url("static/fonts/Gilroy-ExtraBold.otf") format("opentype");
}

@font-face {
  font-family: "Gilroy-ExtraBoldItalic";
  src: url("static/fonts/Gilroy-ExtraBoldItalic.otf") format("opentype");
}

@font-face {
  font-family: "Gilroy-Light";
  src: url("static/fonts/Gilroy-Light.otf") format("opentype");
}

@font-face {
  font-family: "Gilroy-Heavy";
  src: url("static/fonts/Gilroy-Heavy.otf") format("opentype");
}

@font-face {
  font-family: "Gilroy-Medium";
  src: url("static/fonts/Gilroy-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Gilroy-Regular";
  src: url("static/fonts/Gilroy-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Gilroy-SemiBold";
  src: url("static/fonts/Gilroy-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: "Gilroy-Thin";
  src: url("static/fonts/Gilroy-Thin.otf") format("opentype");
}

@font-face {
  font-family: "Gilroy-UltraLight";
  src: url("static/fonts/Gilroy-UltraLight.otf") format("opentype");
}

body {
  margin: 0;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  font-family: "Gilroy-Regular";
  * {
    box-sizing: border-box;
  }
  ::-webkit-scrollbar {
    width: 0;
  }
}
body::-webkit-scrollbar {
  width: 0;
}
h1 {
  font-family: "Gilroy-Bold";
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input,
button {
  cursor: pointer;
  border: unset;
  &:focus {
    outline: none;
  }
}
